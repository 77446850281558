// Import necessary modules from React
import React, { useState, useEffect } from "react";
// Import Link for home button
import { Link } from "react-router-dom";
// Import the CSS file
import "../App.css";

// !!! NOTE TO SELF: ANYTHING LABELED AS PAST EVENTS IS ACTUALLY CURRRENT IDK WHY BUT !!! //
// !!! dumb logic that's why -- but cannot change all occurences or it breaks !!! //

const Blackbear = () => {
  // State variables using the useState hook
  const [pastEvents, setPastEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const [timeUntilNextMeeting, setTimeUntilNextMeeting] = useState(null);
  const [remainingTime, setRemainingTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  // useEffect hook to update remaining time for the current event
  useEffect(() => {
    if (pastEvents.length > 0) {
      const endTime = new Date(pastEvents[0].end.dateTime);
      const currentTime = new Date();

      const timeDifference = endTime - currentTime;

      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setRemainingTime({ hours, minutes, seconds });

      // Update every second
      const intervalId = setInterval(() => {
        setRemainingTime((prevTime) => {
          const newSeconds = prevTime.seconds - 1;
          if (newSeconds < 0) {
            // Reset seconds and subtract one minute
            if (prevTime.minutes === 0 && prevTime.hours === 0) {
              // Countdown reached 0 minutes and 0 seconds, perform desired action
              clearInterval(intervalId); // Clear the interval
              // Perform action (e.g., refresh the page)
              setRefresh(true);
              return { hours: 0, minutes: 0, seconds: 0 };
            } else {
              return {
                hours: prevTime.hours,
                minutes: prevTime.minutes - 1,
                seconds: 59,
              };
            }
          } else {
            return {
              hours: prevTime.hours,
              minutes: prevTime.minutes,
              seconds: newSeconds,
            };
          }
        });
      }, 1000);

      // Clear the interval when the component unmounts or when the event is no longer current
      return () => clearInterval(intervalId);
    }
  }, [pastEvents]);

  // useEffect hook to fetch data when the component mounts
  useEffect(() => {
    setLoading(true); // Set loading to true before fetching data
    fetch(
      "/api/calendar/c_1882nmp4ac1ncj95i267tm55o6fiq@resource.calendar.google.com/events"
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched events data:", data);

        if (data.pastEvents && data.futureEvents) {
          setPastEvents(data.pastEvents);
          setFutureEvents(data.futureEvents);

          // Calculate time until the next meeting
          calculateTimeUntilNextMeeting(data.futureEvents);
        } else {
          console.error("Invalid events data structure:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching (whether success or error) is complete
      });
  }, []);

  // Function to calculate time until the next meeting
  const calculateTimeUntilNextMeeting = async (futureEvents) => {
    if (futureEvents.length > 0) {
      const nextEventStartTime = new Date(futureEvents[0].start.dateTime);
      const currentTime = new Date();
      const timeDifference = nextEventStartTime - currentTime;

      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );

      // Set the timeUntilNextMeeting state
      setTimeUntilNextMeeting({ hours, minutes });
    }
  };

  // useEffect hook to set up an interval for updating time until the next meeting
  useEffect(() => {
    const intervalId = setInterval(() => {
      calculateTimeUntilNextMeeting(futureEvents);
    }, 10000); // Update every min

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }); // The effect depends on the futureEvents state

  // Function to handle booking a room
  // also checks if potential flash meeting times overlap with pre-booked meeting times
  // it already doesn't let peeps book during an active meeting but now we need to do quick maths!
  const handleBookRoom = async (duration) => {
    try {
      if (timeUntilNextMeeting !== null || !isNaN(duration)) {
        const { hours: nextEventHours, minutes: nextEventMinutes } =
          timeUntilNextMeeting || { hours: 0, minutes: 0 };

        // Calculate the total minutes until the next event or default to duration
        const totalMinutesUntilNextEvent =
          nextEventHours * 60 + nextEventMinutes || duration;

        if (duration <= totalMinutesUntilNextEvent) {
          // There's enough time to book the meeting
          await fetch(
            `/api/calendar/c_1882nmp4ac1ncj95i267tm55o6fiq@resource.calendar.google.com/${duration}`,
            { method: "POST" }
          );
          setRefresh(true); // Set refresh to trigger a reload
        } else {
          // Not enough time before the next event or no event time known
          alert(
            "Not enough time before the next event or unable to determine time! Booking anyways..."
          );
          await fetch(
            `/api/calendar/c_1882nmp4ac1ncj95i267tm55o6fiq@resource.calendar.google.com/${duration}`,
            { method: "POST" }
          );
          setRefresh(true); // Set refresh to trigger a reload
        }
      } else {
        // Handle cases where timeUntilNextMeeting is null and no duration is passed
        alert("Unable to determine time until the next event or duration...");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // useEffect for refreshing the page periodically
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefresh(true); // Trigger refresh
    }, 3000); // milli -> seconds
    return () => clearInterval(intervalId);
  }, []);

  // useEffect for handling the data update after an action or at regular intervals
  useEffect(() => {
    if (refresh) {
      fetch(
        "/api/calendar/c_1882nmp4ac1ncj95i267tm55o6fiq@resource.calendar.google.com/events"
      )
        .then((response) => response.json())
        .then((data) => {
          setPastEvents(data.pastEvents || []);
          setFutureEvents(data.futureEvents || []);

          setRefresh(false);
        })
        .catch((error) => console.error("Error fetching events:", error));
    }
  }, [refresh]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#193660", // Navy background color
        }}
      >
        <div
          style={{
            backgroundColor: "white", // White background color
            padding: "20px",
            borderRadius: "10px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h4 style={{ fontSize: "3em", color: "#193660", marginBottom: "20px" }}>
            Loading...
          </h4>
          <div
            className="loader"
            style={{
              border: "6px solid #f3f3f3",
              borderTop: "6px solid #EBA900", // Navy color for loader
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              animation: "spin 2s linear infinite",
              marginBottom: "20px",
            }}
          ></div>
          <button style={{ backgroundColor: "navy" }}>
            <Link to="/" style={{ color: "white", textDecoration: "none" }}>
              Return Home
            </Link>
          </button>
        </div>
      </div>
    );
  } 

  // Function to calculate the duration of a future event
  // const calculateEventDuration = (event) => {
  //   const startTime = new Date(event.start.dateTime);
  //   const endTime = new Date(event.end.dateTime);
  //   const eventDuration = endTime - startTime;

  //   const eventHours = Math.floor(eventDuration / (1000 * 60 * 60));
  //   const eventMinutes = Math.floor(
  //     (eventDuration % (1000 * 60 * 60)) / (1000 * 60)
  //   );

  //   return { hours: eventHours, minutes: eventMinutes };
  // };

  // function to delete/release the flash meeting
  // const deleteEvent = async () => {
  //   if (pastEvents.length > 0) {
  //     const deletedEventId = pastEvents[0].id; // Get the ID of the event to be deleted
  //     await fetch(
  //       `/api/calendar/c_1882nmp4ac1ncj95i267tm55o6fiq@resource.calendar.google.com/events/${deletedEventId}`,
  //       {
  //         method: "DELETE",
  //       }
  //     );
  //     // Filter out the deleted event from pastEvents and set the updated state
  //     const updatedPastEvents = pastEvents.filter(
  //       (event) => event.id !== deletedEventId
  //     );
  //     setPastEvents(updatedPastEvents);
  //   }
  //   setRefresh(true); // Trigger refresh to update data after release
  // };

  // Retrieve the next upcoming event or set it to null if there are none
  const nextEvent = futureEvents.length > 0 ? futureEvents[0] : null;

  // JSX structure for rendering the component
  return (
    <div
      style={{
        backgroundColor:
          pastEvents.length > 0
            ? "rgba(203, 59, 39, 0.7)"
            : "rgba(181, 228, 140, 0.7)",
        padding: "2em",
        borderRadius: "45px",
      }}
    >
      <h1>BlackBear Room</h1>
      {/* Divider line */}
      <hr class="double"></hr>
      {/* Display section for past and future events */}
      <div style={{ color: pastEvents.length > 0 ? "white" : "black" }}>
        {/* Display the most recent past event */}
        <div>
          {pastEvents.length > 0 && (
            <div>
              <div>
                <p style={{ fontSize: "2em", textAlign: 'center' }}>
                  <b>💼 Booked By: </b>
                  {pastEvents.length > 0 && pastEvents[0]?.creator?.email
                    ? pastEvents[0].creator.email
                    : "Creator name is currently unavailable!"}
                </p>
                {/* Display the summary if either admin@firstlightnl.ca is the creator or public@firstlightnl.ca is an attendee */}
                {(pastEvents[0]?.creator?.email === "admin@firstlightnl.ca" ||
                  pastEvents[0]?.attendees.some(
                    (attendee) => attendee.email === "public@firstlightnl.ca"
                  )) && (
                  <p style={{ fontSize: "2em", textAlign: "center" }}>
                    <b>📆 Current Event: </b>
                    {pastEvents[0].summary}
                  </p>
                )}
                <h4 style={{ fontSize: "2em", textAlign: "center" }}>
                  ⛔️ Time Remaining on Current Event: ⛔️
                </h4>
                <hr class="double"></hr>
                <p style={{ fontSize: "2em", textAlign: "center" }}>
                  {remainingTime.hours > 0 && (
                    <span>{remainingTime.hours} hours, </span>
                  )}
                  {remainingTime.minutes} minutes, {remainingTime.seconds}{" "}
                  seconds
                </p>
                {/* <div style={{ display: "inline-block" }}>
                  <button
                    style={{ backgroundColor: "f5ebe0" }}
                    onClick={deleteEvent}
                  >
                    Release Meeting?
                  </button>
                </div>
                <p
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    backgroundColor: "#edafb8",
                    borderRadius: "15px",
                    marginTop: "30px",
                    marginBottom: "0",
                  }}
                >
                  Note: Only the meeting creator should release their meetings!
                </p> */}
              </div>
            </div>
          )}
          {pastEvents.length === 0 && (
            <p style={{ fontSize: "2em", textAlign: "center" }}>
              Status: ✅ Available to book! ✅
            </p>
          )}
        </div>
        {/* Button container with different meeting durations */}
        <div style={{ textAlign: "center" }} className="button-container">
          {/* Button for 30 minutes meeting */}
          <button
            onClick={() => handleBookRoom(30)}
            style={{
              backgroundColor: "white",
              color: pastEvents.length > 0 ? "grey" : "black",
              cursor: pastEvents.length > 0 ? "not-allowed" : "pointer",
              // Conditionally hide if there's less than 30 minutes until the next event
              display:
                pastEvents.length > 0 ||
                (timeUntilNextMeeting &&
                  timeUntilNextMeeting.hours * 60 +
                    timeUntilNextMeeting.minutes <
                    30)
                  ? "none"
                  : "inline",
            }}
            disabled={pastEvents.length > 0}
          >
            Book 30-Min Meeting
          </button>
          {/* Button for 45 minutes meeting */}
          <button
            onClick={() => handleBookRoom(45)}
            style={{
              backgroundColor: "white",
              color: pastEvents.length > 0 ? "grey" : "black",
              cursor: pastEvents.length > 0 ? "not-allowed" : "pointer",
              display:
                pastEvents.length > 0 ||
                (timeUntilNextMeeting &&
                  timeUntilNextMeeting.hours * 60 +
                    timeUntilNextMeeting.minutes <
                    45)
                  ? "none"
                  : "inline",
            }}
            disabled={pastEvents.length > 0}
          >
            Book 45-Min Meeting
          </button>
          {/* Button for 60 minutes meeting */}
          <button
            onClick={() => handleBookRoom(60)}
            style={{
              backgroundColor: "white",
              color: pastEvents.length > 0 ? "grey" : "black",
              cursor: pastEvents.length > 0 ? "not-allowed" : "pointer",
              display:
                pastEvents.length > 0 ||
                (timeUntilNextMeeting &&
                  timeUntilNextMeeting.hours * 60 +
                    timeUntilNextMeeting.minutes <
                    60)
                  ? "none"
                  : "inline",
            }}
            disabled={pastEvents.length > 0}
          >
            Book 60-Min Meeting
          </button>
        </div>
        <br />
       {/* Display the next upcoming event */}
       <div
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.5)", // 0.5 alpha value for 50% transparency
            padding: "1em",
            borderRadius: "45px",
          }}
        >
          <h2 style={{ textAlign: "center", marginTop: "0" }}>Next Event:</h2>
          {/* Divider line */}
          <hr class="double"></hr>
          {timeUntilNextMeeting !== null ? (
            <p style={{textAlign: "center"}}>
              {/* <b>Duration: </b>
              {calculateEventDuration(nextEvent).hours} hours,{" "}
              {calculateEventDuration(nextEvent).minutes} minutes <br></br> */}
              <b>📆 Date of Meeting:</b>{" "}
              {new Date(nextEvent.start.dateTime).toLocaleDateString()}
              <br></br>
              <b>⏰ Start Time of Meeting:</b>{" "}
              {new Date(nextEvent.start.dateTime).toLocaleTimeString()} (in{" "}
              {timeUntilNextMeeting.hours > 0
                ? `${timeUntilNextMeeting.hours} hours and `
                : ""}
              {timeUntilNextMeeting.minutes} minutes)<br></br>
              <b>⏰ End Time of Meeting:</b>{" "}
              {new Date(nextEvent.end.dateTime).toLocaleTimeString()}
              <br></br>
              <b>💼 Booked By: </b>
              {nextEvent?.creator?.email}
              {/* <b>Date and Start-Time of Meeting: </b>
              {new Date(nextEvent.start.dateTime).toLocaleString()} (in{" "}
              {timeUntilNextMeeting.hours > 0
                ? `${timeUntilNextMeeting.hours} hours and `
                : ""}
              {timeUntilNextMeeting.minutes} minutes) */}
            </p>
          ) : (
            <p style={{ textAlign: "center" }}>✅ No upcoming events ✅</p>
          )}
        </div>
      </div>
      <br />
    </div>
  );
};

// Export the component as the default export
export default Blackbear;
