import React from 'react';
import { Link } from 'react-router-dom';
import Card from './Card';
import "../../src/App.css";

const linkStyle = {
  textDecoration: 'none',
  color: '#000',
};

const titleStyle = {
  fontWeight: 'bold',
};

const CardGrid = ({ cards }) => {
  // Divide the array of cards into three separate arrays
  const columns = [[], [], []];
  cards.forEach((card, index) => {
    columns[index % 3].push(card);
  });

  return (
    <div className="card-container">
      {/* Render each column */}
      {columns.map((column, columnIndex) => (
        <div key={columnIndex} className="card-column">
          {/* Render cards in the column */}
          {column.map((card, index) => (
            <Link to={`/${card.title.toLowerCase().replace(' ', '-')}`} key={index} style={linkStyle}>
              <Card
                title={<span style={titleStyle}>{card.title}</span>}
                color={card.color}
                pastEvents={card.pastEvents}
                hasTechTools={card.hasTechTools}
                maxCapacity={card.maxCapacity}
              />
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CardGrid;